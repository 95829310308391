@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Alanon";
  src: url("./assets/fonts/Alanon/Alanon.otf");
}

@import url("https://fonts.googleapis.com/css2?family=Titan+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gluten:wght@100;400&display=swap");

body {
  margin: 0;
  font-family: "Gluten";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2a0c62;
  scroll-behavior: smooth;
}
.App {
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.slick-slide {
  div {
    padding: 20px;
  }
}

.slick-slide img {
  border-radius: 30px;
}
